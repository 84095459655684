<template>
  <div id="verify-email" class=" ">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :opacity="0.97" :z-index="2147480000"></loading>
    <span class="c-account-box__logo">
      <img class="mob-logo" :src="baseUrl + 'img/logo.png'" :alt="app_name + ' logo'" />
    </span>
    <div class="c-account-box__headline">Confirmação de E-mail</div>
    <div class="c-account-box__content">
      <div class="c-form-account__title c-form-account__title--inline">{{message}}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { apiBaseUrl } from "@/services/api.js";
import { setTimeout } from "timers";
import router from "@/router";
import HeaderBuilder from "@/services/header-builder.js";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet


export default {
  name: "VerifyEmail",
  components: {
    Loading
  },
  data: function() {
    return {
      message: "",
      isLoading: false,
      app_name: process.env.VUE_APP_NAME,
      tenant_id: process.env.VUE_APP_TENANT_ID,
      baseUrl: process.env.BASE_URL,
    };
  },
  props: {
    target_url: String
  },
  beforeDestroy() {
    this.$root.$emit("showWholeApp");
  },
  mounted() {
    this.$root.$emit("showOnlyRouter");

    this.message = "Verificando seu e-mail...";

    let artifact = atob(
      decodeURIComponent(this.target_url.replace(/[-]/g, "+").replace(/[_]/g, "/"))
    );

    let url = new URL(artifact);
    let baseUrl = new URL(apiBaseUrl);
    if (url.hostname != baseUrl.hostname) {
      this.message = "Erro de segurança";
      
      
      
      
      return;
    }

    this.isLoading = true;
    axios.put(artifact, null, { headers: HeaderBuilder.build() }).then(
      response => {
        this.message = "E-mail verificado com sucesso! Redirecionando...";
        setTimeout(() => {
          router.push({ name: "login" });
        }, 1000);
      },
      error => {
        // eslint-disable-next-line
        console.error(error);
        this.message = "Erro ao verificar seu e-mail!";
      }
    ).finally(() => {
      this.isLoading = false;
    });
  }
};
</script>
